<template>
  <div class="form-control">
    <div class="filter-checkboxes gap-[30px] mt-6 flex rounded-xl drop-shadow-xl text-left">
      <label
          v-for="control in formControls"
          :key="control.id"
          class="label cursor-pointer button"
      >
        <span class="label-text text-black mr-2 inline-block">{{ control.label }}</span>

        <input
            type="checkbox"
            v-model="control.selected"
            @change="filterPlaygrounds()"
            class="checkbox checkbox-primary"
        >
      </label>
    </div>
  </div>
</template>

<script>
export default  {
  props: {},
  data () {
    return {
      formControls: [
        { label: 'Has Slides', id: 'slides', selected: false },
        { label: 'Has Bathrooms', id: 'bathrooms', selected: false },
        { label: 'Toddler Friendly', id: 'toddlerFriendly', selected: false },
        { label: 'Has Fireman Pole', id: 'firemanPole', selected: false },
      ]
    }
  },
  methods: {
    filterPlaygrounds() {
      // Create a list of the details to check for by keeping only those that are selected
      // then returning only the "id" which is the index in the details property.
      const selectedList = this.formControls
          .filter(control => control.selected)
          .map(selected => selected.id);
      this.$emit('filter', selectedList);
    }
  }
}
</script>