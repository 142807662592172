<template>
  <l-map
      :center="Coordinates"
      :zoom="zoom"
      class="map md:h-128 bg-white dark:bg-slate-800 shadow-lg ring-1 ring-black/5 rounded-xl flex flex-col divide-y dark:divide-slate-200/5 drop-shadow-xl w-full md:w-9/12"
      ref="map"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
  >
    <l-tile-layer :url="url" />
    <l-marker v-for="Playground in Playgrounds"
              :key="Playground.id"
              :interactive="true"
              :name="Playground.name"
              :lat-lng="Playground.location.coordinates"
    >
      <l-tooltip>{{Playground.name}}</l-tooltip>
      <l-popup>
        <h3>{{Playground.name}}</h3>
      </l-popup>
    </l-marker>
  </l-map>
</template>


<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPopup } from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';
export default {
  props: {
    Playgrounds: Object,
    Coordinates: Array,
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: this.Coordinates,
      zoom: 12,
    }
  },
  methods: {
    zoomUpdated (zoom) {
      this.zoom = zoom;
      // console.log('Zoom updated');
      // console.log(this.markers);
    },
    centerUpdated (center) {
      console.log('Center:');
      console.log(center);
      let coords = [center.lat, center.lng];
      this.$emit('recentered', coords);
    }
  }
}
</script>

<style>
.map {
  position: absolute;
  overflow: hidden;
  width: 100% !important;
}

@media (min-width: 768px) {
  .map {
    width: 75% !important;
  }
}
</style>