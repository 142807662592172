<template>
  <div class="app mx-4">
    <header class="">
      <h1 class="text-4xl text-center font-bold">Playground Finder</h1>
    </header>
    <div class="flex flex-col w-full border-opacity-50">
      <div class="grid card bg-base-300 rounded-box border-gray-300 border-1 overflow-x-scroll">
        <div class="filters rounded bg-white text-black text-left">
          <LocationSearch @change-location="changeLocation" />
          <PlaygroundFilters @filter="filterPlaygrounds" />
        </div>
      </div>
      <div class="divider"></div>
      <div class="grid card bg-base-100 rounded-box">
        <div class="grid grid-cols-1 md:grid-cols-4  md:gap-4 rounded">
          <div class="md:col-span-1">
            <PlaygroundList :Playgrounds="Playgrounds" />
          </div>
          <div class="mt-6 md:mt-0 md:col-span-3">
            <PlaygroundMap @recentered="recenterMap" :Playgrounds="Playgrounds" :Coordinates="Coordinates" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationSearch from "./components/LocationSearch.vue";
import PlaygroundMap from './components/PlaygroundMap.vue'
import PlaygroundList from "./components/PlaygroundList.vue";
import PlaygroundFilters from "./components/PlaygroundFilters.vue";
import PlaygroundsData from './playgrounds-advanced.json';
const startingCoordinates = [38.94, -94.66];

export default {
  name: 'App',
  components: {
    LocationSearch: LocationSearch,
    PlaygroundMap: PlaygroundMap,
    PlaygroundList: PlaygroundList,
    PlaygroundFilters: PlaygroundFilters
  },
  data() {
    return {
      Playgrounds: PlaygroundsData,
      Coordinates: startingCoordinates
    }
  },
  methods: {
    filterPlaygrounds(selected) {
      let playgrounds = PlaygroundsData;

      selected.forEach((val) => {
        playgrounds = playgrounds.filter(playground => playground.details[val]);
      })

      this.Playgrounds = playgrounds;
    },
    changeLocation(location) {
      console.log('Change location called...');
      console.log(location);

      // Get the coordinates then push the new center to the map.
      let coords = [location.latitude, location.longitude];
      this.Coordinates = coords;
    },
    recenterMap(coords) {
      if (this.Coordinates !== coords) {
        this.Coordinates = coords;
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
