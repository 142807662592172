<template>
  <div class="h-48 md:h-128 overflow-y-scroll relative max-w-sm mx-auto bg-white dark:bg-slate-800 shadow-lg ring-1 ring-black/5 rounded-xl flex flex-col divide-y dark:divide-slate-200/5 drop-shadow-xl text-left">
    <div v-for="Playground in Playgrounds"
         :key="Playground.id"
         class="flex items-center gap-4 p-4"
    >
      <div class="flex flex-col">
        <div class="text-slate-900 text-sm font-medium dark:text-slate-200">
          {{Playground.name}}
        </div>
        <span class="text-slate-500 text-sm font-medium dark:text-slate-400">
          {{Playground.plusCode}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Playgrounds: Object
  }
}
</script>