<template>
  <div class="form-control">
    <label class="label">
      <span class="label-text">Location Search</span>
    </label>

    <vue-google-autocomplete
      ref="address"
      id="map"
      classname="w-1/2 input input-bordered w-full max-w-xs"
      placeholder="Please type your location"
      v-on:placechanged="getAddressData"
      country="us"
      types="locality"
    >
    </vue-google-autocomplete>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete },

  data: function () {
    return {
      address: "",
    };
  },

  mounted() {
    // To demonstrate functionality of exposed component functions
    // Here we make focus on the user input
    this.$refs.address.focus();
  },

  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object (implement for photos)
     */
    getAddressData: function (addressData) {
      this.address = addressData;
      this.$emit('change-location', addressData);
      console.log('Address Data:');
      console.log(addressData);
    },
  },
};
</script>